import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ROUTES_ALWAYS_SHOWN } from 'app/models/page-routes.models';
import { GraphqlApiService } from 'app/services/apis/graphql/graphql.service';
import { GQL_QUERY_AUTHORIZATION_PATH_ALLOWED } from 'app/services/apis/graphql/query/graphql.query.authorization.roles';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    private notify: NbToastrService,
    private graphqlApi: GraphqlApiService,
    private translate: TranslateService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    const url = this.getUrl(state);

    // Always allow if path is in always-allowed const
    if (ROUTES_ALWAYS_SHOWN.includes(url))
      return true;

    return this.isAuthorized(url);
  }

  /**
   * Filter out ID's with regex
   * @param state router state snapshot
   * @returns URL without ID
   */
  private getUrl(state: RouterStateSnapshot) {
    const pathSegments = state.url.split('/');
    const url = pathSegments.filter(a => !a.match(/(\d).*/)).join('/');
    return url;
  }

  /**
   * Check if user is allowed to use this path
   */
  private isAuthorized(url: string): Observable<boolean> {

    return this.graphqlApi.query({
      query: GQL_QUERY_AUTHORIZATION_PATH_ALLOWED,
      cachePolicy: 'cache-first',
      variables: {
        path: url
      }
    }).pipe(
      map(result => {
        var allowed = result['allowed'] as boolean;
        if (!allowed) {
          this.notify.danger(
            this.translate.instant("LOGIN.NO_ACCESS"),
            this.translate.instant("LOGIN.NO_ACCESS_TITLE")
          );
        }
        return allowed;
      })
    );
  }

}
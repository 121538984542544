import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, PopupRequest, SilentRequest } from '@azure/msal-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PageRoutes } from 'app/models/page-routes.models';
import { STORAGE_KEY_TOKEN } from 'app/models/storage/local-storage.models';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';
import { UserRole } from '../../models/roles.models';

const NOT_AUTHENTICATED_USER: UserData = {
  name: '',
  email: '',
  roles: [],
  jwt: ''
};

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _jwtHelper = new JwtHelperService();
  private currentUser$: BehaviorSubject<UserData> = new BehaviorSubject<UserData>(NOT_AUTHENTICATED_USER);

  constructor(
    private router: Router,
    private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ) {
    this.currentUser$.pipe(
      skip(1),
    ).subscribe(user => {
      if (!this.authenticated) {
        this.router.navigateByUrl(PageRoutes.Login);
      } else {
        // Set refresh token timeout
        const expiresInMs = this._jwtHelper.getTokenExpirationDate(user.jwt).getTime() - new Date().getTime();
        this.setRefreshTokenTimeout(expiresInMs);
      }
    });

    // on init load settings from storage to make sure we keep state on refresh
    const token = localStorage.getItem(STORAGE_KEY_TOKEN);
    if (!!token) {
      const tokenInfo = new JwtHelperService().decodeToken(token);
      this.currentUser$.next({
        email: tokenInfo.preferred_username,
        name: tokenInfo.name,
        roles: tokenInfo.roles ?? [],
        jwt: token
      });
    }
  }

  get authenticated(): boolean {
    const isTokenExpired = this._jwtHelper.isTokenExpired(this.user.value.jwt);
    const isValid = !!this.user.value.email && !isTokenExpired;
    return isValid;
  }

  get user(): BehaviorSubject<UserData> {
    return this.currentUser$;
  }

  get roles(): string[] {
    return this.user.value.roles ?? [];
  }

  loginPopup() {
    return this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest);
  }

  setAuthorization(auth: AuthenticationResult) {
    this.authService.instance.setActiveAccount(auth.account);
    const tokenInfo = new JwtHelperService().decodeToken(auth.idToken);
    this.currentUser$.next({
      email: tokenInfo.preferred_username,
      name: tokenInfo.name,
      roles: auth.idTokenClaims['roles'],
      jwt: auth.idToken
    });
    localStorage.setItem(STORAGE_KEY_TOKEN, auth.idToken);
  }

  clearAuthorization() {
    this.currentUser$.next(NOT_AUTHENTICATED_USER);
    localStorage.setItem(STORAGE_KEY_TOKEN, '');
  }

  /**
   * Refreshes token to keep user logged in
   */
  refreshToken() {
    console.debug('refreshing token');
    const req: SilentRequest = {
      scopes: ['user.read'],
      account: this.authService.instance.getActiveAccount(),
      forceRefresh: true // Force refresh because of bug in MSAL
    };

    // Try TokenSilent first, fallback on loginPopup
    this.authService.acquireTokenSilent(req).subscribe(
      {
        error: e => {
          this.loginPopup().pipe(
            untilDestroyed(this)
          ).subscribe({
            error: e => {
              console.warn('loginPopup error', e);
            },
            next: (x: AuthenticationResult) => {
              this.setAuthorization(x);
            }
          });
        },
        next: (x: AuthenticationResult) => {
          this.setAuthorization(x);
        }
      }
    );
  }

  setRefreshTokenTimeout(expiresInMs: number) {
    // Show expire time in console
    var expiresDate = new Date();
    expiresDate.setMilliseconds(expiresDate.getMilliseconds() + expiresInMs);
    console.debug(`Token expires in ${Math.floor(expiresInMs / (60 * 1000))} minutes ( ${expiresDate.getHours().toString().padStart(2, "0")}:${expiresDate.getMinutes().toString().padStart(2, "0")}:${expiresDate.getSeconds().toString().padStart(2, "0")} )`);

    // Calculate timeout, 5 min before token expires
    const timeout = expiresInMs - (5 * 60 * 1000);
    setTimeout(() => {
      this.refreshToken();
    }, timeout);
  }
}

interface UserData {
  email: string;
  name: string;
  roles?: UserRole[];
  jwt: string;
}